@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































































@import '@/assets/scss/_variables.scss';
.Auth_root {
  width: 100%;
  min-height: 100vh;
  background: #0f1f3f;
  background-image: url('../../../assets/img/auth/auth_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  overflow: auto;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
